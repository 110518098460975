/*  Wave
      <div class="skWave">
        <div class="skWave-rect"></div>
        <div class="skWave-rect"></div>
        <div class="skWave-rect"></div>
        <div class="skWave-rect"></div>
        <div class="skWave-rect"></div>
      </div>
 */

.skWave {
  --sk-size: 40px;
  --sk-color: #9e9e9e;
}

.skWave {
  width: var(--sk-size);
  height: var(--sk-size);
  display: flex;
  justify-content: space-between;
}

.skWaveRect {
  background-color: var(--sk-color);
  height: 100%;
  width: 15%;
  animation: sk-wave 1.2s infinite ease-in-out; 
}

.skWaveRect:nth-child(1) { animation-delay: -1.2s; }
.skWaveRect:nth-child(2) { animation-delay: -1.1s; }
.skWaveRect:nth-child(3) { animation-delay: -1.0s; }
.skWaveRect:nth-child(4) { animation-delay: -0.9s; }
.skWaveRect:nth-child(5) { animation-delay: -0.8s; }

@keyframes sk-wave {
  0%, 40%, 100% {
    transform: scaleY(0.4); 
  } 20% {
    transform: scaleY(1); 
  } 
}
